jQuery(document).ready( function() {

  jQuery(".jumpLink").click(function(e) {
    var linkHref = jQuery(this).attr("href");
    jQuery('html, body').animate({
      scrollTop: jQuery(linkHref).offset().top
    }, 300);
    e.preventDefault();
  });

}); // ends document.ready
